
.dd-timeline-content {
	width: 100%;
	height: 100%;
	overflow: hidden; }

.timeline {
	width: calc(100% + 40px);
	background: url("/img/about/bg-m.jpg") no-repeat 55% center fixed;
	background-size: cover;

	&::-webkit-scrollbar {
		display: none; }

	@media screen and (min-width: $size-web) {
		height: 82vh;
		display: block;
		position: relative;
		overflow: auto;
		margin-bottom: -5.5rem;
		padding-bottom: 200px;
		background: none !important; } }

.timeline-content {
	width: 100%;
	display: inline-block;
	position: relative;
	padding-bottom: 30px;
	@media screen and (min-width: $size-web) {
		padding-bottom: 105px;
		margin-bottom: 100px; } }

.odometer-inside {
	@media screen and (min-width: $size-web) {
		@media screen and (max-height: 700px) {
			height: 100px;
			display: flex;
			overflow: hidden; } } }

.odometer-theme-car {
	background: none !important;
	padding: 0 !important;
	font-family: 'Eurostile LT Std' !important;
	color: #a3a5ac !important;
	.odometer-digit {
		background: none !important;
		color: $light !important;
		font-size: 3rem;
		padding: 0 3px !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		font-weight: bold !important;

		@media screen and (min-width: $size-web) {
			font-size: 7.3rem !important;
			height: 120px;
			color: #a3a5ac !important;
			padding: 0 10px !important;
			font-weight: normal !important;

			@media screen and (max-height: 700px) {
				font-size: 6rem !important; } } } }

.timeline-letters-mobile {
	position: fixed;
	z-index: 999;
	width: 100%;
	text-align: center;
	top: 25%;

	@media screen and (min-width: $size-web) {
		display: none; } }

.timeline-letters-mobile-number {
	font-size: 2.200rem;
	color: #a3a5ac;
	margin-left: -15px;

	@media screen and (min-width: $size-web) {
		margin-left: 0; } }

.timeline-letters {
	position: fixed;
	bottom: 190px;
	margin-left: -655px;
	z-index: 999999;
	left: 50%;
	background: url(/img/about/letters.png) top left no-repeat;
	width: 435px;
	height: 385px;
	padding-top: 60px;
	display: none;
	@media screen and (min-width: $size-web) {
		display: inline-block; }

	@media screen and (max-height: 700px) {
		background-size: contain;
		height: 300px;
		margin-top: 80px;
		bottom: 130px;
		padding-top: 50px;
		margin-left: -550px; } }

.timeline-letters-number {
	font-size: 7.3rem;
	color: #a3a5ac;
	font-weight: bold;
	margin-left: 180px;
	text-align: right !important;
	-ms-transform: rotate(-1deg); /* IE 9 */;
	-webkit-transform: rotate(-1deg); /* Safari */;
	transform: rotate(-1deg);

	@media screen and (max-height: 700px) {
		margin-left: 135px; } }

.timeline-letters-number-year {
	.odometer-digit:nth-child(1) {
		display: none; }
	.odometer-digit:nth-child(2) {
		display: none; } }

.active-search .timeline-stage {
	margin-top: 40px;
	transition: .3s; }

.timeline-stage {
	position: fixed;
	top: unset;
	bottom: 0;
	width: 100%;
	height: 90px;
	z-index: 999;
	background-image: url(/img/about/bg_stage.jpg);
	background-size: 320%;
	pointer-events: none;
	background-position: 50% top;
	background-repeat: repeat-x;

	@media screen and (min-width: $size-tablet) {
		background-size: 230%;
		height: 150px; }

	@media screen and (min-width: 820px) {
		background-size: 142%;
		height: 100px; }

	@media screen and (min-width: $size-web) {
		background-size: cover;
		background-position: center;
		height: 215px;
		top: 68vh;
		bottom: 0; } }

.timeline-spacing {
	width: 100%;
	height: 100vh;
	@media screen and (min-width: $size-web) {
		height: 70vh;
		display: none; } }

.timeline-comp {
	position: absolute;
	z-index: 9999;
	top: unset !important;
	bottom: 30px;
	left: 0px;
	width: 100%;
	text-align: center;
	img {
		width: 50%;
		@media screen and (min-width: 540px) {
			width: 40%; }
		@media screen and (min-width: 610px) {
			width: 35%; }
		@media screen and (min-width: 700px) {
			width: 30%; }
		@media screen and (min-width: 820px) {
			width: 25%; }

		@media screen and (min-width: $size-web) {
			max-height: 42vh; } }

	@media screen and (min-width: 500px) {
		bottom: 30px; }

	@media screen and (min-width: $size-web) {
		left: 280px;
		top: auto !important;
		bottom: 100px;
		width: auto;
		img {
			width: auto !important; } }

	.timeline-arrow-down {
		bottom: -25px !important;

		@media screen and (min-width: $size-web) {
			display: none; }

		a {
			pointer-events: auto !important; } } }

.timeline-comp-1960 {
	top: 90px;

	img {
		width: 140px; }

	@media screen and (min-width: $size-tablet) {
		img {
			width: 25%; } }

	@media screen and (min-width: $size-web) {
		top: auto;
		left: 230px; } }

.timeline-comp-1970 {
	img {
		max-height: 42vh;
		width: 160px; }

	@media screen and (min-width: $size-tablet) {
		img {
			width: 25%; } }

	@media screen and (min-width: $size-web) {
		left: 180px !important; } }

.timeline-comp-1980 {
	@media screen and (min-width: $size-web) {
		left: 250px !important;
		top: auto !important;
		bottom: 50px; } }

.timeline-comp-1990 {
	@media screen and (min-width: $size-web) {
		left: 280px;
		top: auto !important;
		bottom: 70px; }

	img {
		@media screen and (min-width: $size-web) {
			max-height: 32vh; } } }

.timeline-comp-2000 {
	img {
		@media screen and (min-width: 560px) {
			width: 40%; }
		@media screen and (min-width: 690px) {
			width: 30%; }
		@media screen and (min-width: 910px) {
			width: 25%; } }

	@media screen and (min-width: $size-web) {
		left: 270px !important;
		top: auto !important;
		img {
			width: auto; } } }

.timeline-comp-2011 {
	@media screen and (min-width: $size-web) {
		left: 320px !important;
		top: auto !important; } }

.timeline-comp-2012 {
	img {
		width: 40%;

		@media screen and (min-width: 500px) {
			width: 30%; } }

	@media screen and (min-width: $size-web) {
		left: 320px;
		top: auto !important; } }

.timeline-comp-2014 {
	img {
		width: 60%;

		@media screen and (min-width: 500px) {
			width: 45%; }

		@media screen and (min-width: $size-web) {
			max-height: 26vh; } }

	@media screen and (min-width: $size-web) {
		left: 260px !important;
		bottom: 75px;
		top: auto !important; } }

.timeline-comp-2016 {
	img {
		width: 40%;

		@media screen and (min-width: 500px) {
			width: 30%; } }

	@media screen and (min-width: $size-web) {
		left: 280px !important; } }

.timeline-comp-2017 {
	img {
		width: 95%;

		@media screen and (min-width: 500px) {
			width: 100% !important; } }

	@media screen and (min-width: $size-web) {
		left: 310px !important;
		bottom: 90px;
		top: auto !important; } }

.timeline-banner {
	width: 100%;
	height: 100vh;
	background-position: 50% center;
	background-repeat: no-repeat;
	background-size: auto 100%;
	background-image: none !important;
	background-color: transparent;

	@media screen and (min-width: $size-web) {
		height: 70vh;
		top: 0;
		position: fixed;
		position: relative !important;
		top: unset !important;
		bottom: unset !important; } }

@-moz-document url-prefix() {
	.timeline-banner.lastTimeline {
		@media screen and (min-width: $size-web) {
			top: -110px !important; } } }

.timeline-banner-1946 {
	position: fixed;
	background-image: url(/img/about/bg-m.jpg);

	@media screen and (min-width: $size-web) {
		position: auto;
		background-image: url(/img/about/1946.jpg) !important; }

	.timeline-banner-text {
		bottom: 20%;
		@media screen and (min-width: $size-web) {
			bottom: 22vh;
			top: unset; }

		@media screen and (max-height: 675px) {
			width: 270px; } } }

.timeline-banner-1960 {
	background-image: url(/img/about/bg-m.jpg);

	@media screen and (min-width: $size-web) {
		background-image: url(/img/about/1960.jpg) !important; }

	.timeline-banner-text {
		@media screen and (min-width: $size-web) {
			bottom: 31.5vh;
			top: unset; } } }

.timeline-banner-1970 {
	background-image: url(/img/about/bg-m.jpg);

	@media screen and (min-width: $size-web) {
		background-image: url(/img/about/1970.jpg) !important; } }

.timeline-banner-1980 {
	background-image: url(/img/about/bg-m.jpg);

	@media screen and (min-width: $size-web) {
		background-image: url(/img/about/1980.jpg) !important; }

	.timeline-banner-text {
		@media screen and (min-width: $size-web) {
			width: 410px;
			top: unset;
			bottom: 22.5vh;
			right: 30px; }

		@media screen and (max-height: 790px) {
			bottom: 32vh; } } }

.timeline-banner-1990 {
	background-image: url(/img/about/bg-m.jpg);

	@media screen and (min-width: $size-web) {
		background-image: url(/img/about/1990.jpg) !important; }

	.timeline-banner-text {
		@media screen and (min-width: $size-web) {
			width: 410px;
			top: unset;
			bottom: 25vh; }

		@media screen and (max-height: 790px) {
			bottom: 30vh; } } }

.timeline-banner-2000 {
	background-image: url(/img/about/bg-m.jpg);
	@media screen and (min-width: $size-web) {
		background-image: url(/img/about/2000.jpg) !important; }

	.timeline-banner-text {
		@media screen and (min-width: $size-web) {
			width: 410px;
			top: unset;
			bottom: 21vh; }

		@media screen and (max-height: 790px) {
			bottom: 25vh; } } }

.timeline-banner-2011 {
	background-image: url(/img/about/bg-m.jpg);
	@media screen and (min-width: $size-web) {
		background-image: url(/img/about/2011.jpg) !important; }

	.timeline-banner-text {
		@media screen and (min-width: $size-web) {
			width: 320px; } } }

.timeline-banner-2012 {
	background-image: url(/img/about/bg-m.jpg);

	@media screen and (min-width: $size-web) {
		background-image: url(/img/about/2012.jpg) !important; }

	.timeline-banner-text {
		span {
			display: none; }
		@media screen and (min-width: $size-web) {
			top: unset;
			bottom: 14vh;

			span {
				display: inline; } }

		@media screen and (max-height: 790px) {
			bottom: 22vh; }

		@media screen and (max-height: 675px) {
			width: 300px; }

		p {
			@media screen and (max-height: 790px) {
				font-size: 0.9rem;
				line-height: 1.2rem; } } } }

.timeline-banner-2014 {
	background-image: url(/img/about/bg-m.jpg);

	@media screen and (min-width: $size-web) {
		background-image: url(/img/about/2014.jpg) !important; }

	.timeline-banner-text {
		@media screen and (min-width: $size-web) {
			width: 500px;
			top: unset;
			bottom: 24vh; }

		@media screen and (max-height: 790px) {
			bottom: 30vh; } } }

.timeline-banner-2016 {
	background-image: url(/img/about/bg-m.jpg);

	@media screen and (min-width: $size-web) {
		background-image: url(/img/about/2016.jpg) !important; }

	.timeline-banner-text {
		@media screen and (min-width: $size-web) {
			width: 430px;
			top: unset;
			bottom: 25vh; }

		@media screen and (max-height: 790px) {
			bottom: 30vh; }

		p {
			@media screen and (max-width: 767px) {
				max-width: 320px;
				margin: 15px auto; } } } }

.timeline-banner-2017 {
	background-image: url(/img/about/bg-m.jpg);

	@media screen and (min-width: $size-web) {
		background-image: url(/img/about/2017.jpg) !important; }

	.timeline-banner-text {
		@media screen and (min-width: $size-web) {
			width: 480px;
			top: unset;
			bottom: 24vh; }

		@media screen and (max-height: 790px) and (min-width: 1024px) {
			// bottom: 26vh
			width: 550px;
			bottom: 30vh; }

		p {
			@media screen and (max-height: 790px) {
				font-size: 0.9rem;
				line-height: 1.2rem; }

			@media screen and (max-width: 1024px) {
				max-width: 340px;
				margin: 15px auto; } } } }

.timeline-banner-align {
	width: 100%;
	max-width: 960px;
	margin: 0 auto;
	height: 100%;
	position: relative; }

.timeline-banner-text {
	position: absolute;
	top: 35%;
	right: 0;
	width: 100%;
	margin: 0;
	padding: 0 10px;
	opacity: 0;
	transition: 0.3s;

	&.activeText {
		opacity: 1; }

	@media screen and (max-width: $size-tablet - 1px) {
		margin: 0 auto;
		left: 0; }

	@media screen and (min-width: 400px) {
		padding: 0 15%; }

	@media screen and (min-width: $size-web) {
		width: 245px;
		top: 38vh;
		padding: 0; }

	@media screen and (max-height: 790px) {
		top: 22vh; }

	p {
		color: $light;
		font-size: .8rem;
		text-align: center;
		font-weight: 500;
		font-style: normal;
		letter-spacing: .9px;
		line-height: 1rem;

		@media screen and (min-width: $size-tablet) {
			font-size: 1.5rem;
			line-height: 2rem; }

		@media screen and (min-width: $size-web) {
			font-size: 1rem;
			text-align: left;
			line-height: 1.5rem; } } }

.timeline-menu {
	position: absolute;
	top: 40px;
	z-index: 999999;
	width: 100%;
	display: none;
	@media screen and (min-width: $size-web) {
		display: inline-block; }

	.products-submenu {
		height: 55px;
		display: flex !important;
		border-color: $light;
		padding: 0 0.5rem; }
	a {
		color: $light;
		font-size: 0.938rem;
		font-weight: 500;
		letter-spacing: .9px; }

	.products-submenu_item {
		width: 100px; }

	.products-submenu_link {
		&:hover,
		&:focus,
		&.active {
			font-size: 1.125rem !important;
			font-weight: normal;
			text-shadow: 0 0 2px $light;

			&:after {
				border-bottom: 6px solid $light;
				bottom: -17px; } } } }

.timeline-footer-content {
	margin-top: -65px;
	background-position: top;
	padding-bottom: 80px;

	@media screen and (min-width: $size-web) {
		margin-top: 0;
		padding: 0; } }

.about-footer-timeline {
	bottom: 40px;
	z-index: 99;
	flex-direction: column;
	height: 150px;
	clip-path: none;

	.products-button {
		margin: .5rem; }

	@media screen and (min-width: $size-web) {
		flex-direction: row;
		bottom: -8rem;
		height: 10rem;
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 70%);

		.products-button {
			margin: 1rem; } } }

.timeline-arrow-down {
	position: absolute;
	bottom: 10px;
	z-index: 99999999;
	width: 100%;
	text-align: center;
	img {
		width: auto; }

	@media screen and (min-width: $size-web) {
		bottom: 15%;
		display: none; } }
