.lightbox {
	display: none;
	width: 90%;
	max-width: 690px;
	min-height: 200px;
	background-color: rgba(255,255,255,0.8);
	border: 3px solid #ee3a43;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 999999; }

.lightbox-container {
	width: 100%;
	position: relative;
	display: inline-block;
	padding: 20px 0 0 0;

	p {
		font-size: 1.050rem;
		font-weight: bold;
		margin: 40px 0 0 0;
		text-align: center;
		padding: 0 20px 0 0px; } }


.lightbox-close {
	position: absolute;
	top: 5px;
	right: 5px; }

.lightbox-title {
	font-size: 1.250rem;
	margin: 0;
	padding: 0;
	font-weight: 500;
	text-align: center;
	color: #116ea4; }

.lightbox-list {
	width: 100%;
	padding: 0;
	margin: 10px 0 0 2%;
	list-style: none; }


.lightbox-list-logo {
	width: 45%;
	margin: 5px 1%;
	display: inline-block;
	padding: 0;
	border: 1px solid #959595;
	min-height: 35px;
	background-color: #FFF;

	@media screen and (min-width: $size-tablet) {
		width: 23%;
		margin: 5px 0.5%; }

	img {
		width: 100%;
		margin: 0;
		padding: 0;
		float: left; } }
