.related-product {
    width: 100%;
    margin: 60px 0;
    &__title {
        width: 100%;
        text-align: center;
        color: $dark;
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: normal;
        font-style: italic; }
    &__carousel {
        width: 90%;
        display: inline-block;
        margin: 20px auto 0 auto;
        float: none;
        position: relative; }
    &__card {
        width: 100%;
        padding: 0 10px 10px 10px;
        border: 1px solid $colorGrey;
        border-bottom: 4px solid $second-color !important; }

    &__content {
        display: block;
        width: 100%;
        min-height: 45px; }

    .owl-item img {
        width: 100%;
        display: inline-block;
        @media screen and (min-width: $size-tablet) {
            width: auto; } }
    .card__buy img {
        width: auto; }
    .owl-prev {
        position: absolute;
        top: 20%;
        left: -8%;
        background: none !important;
        @media screen and (min-width: $size-tablet) {
            top: 17%; } }
    .owl-next {
        position: absolute;
        top: 20%;
        right: -8%;
        background: none !important;
        @media screen and (min-width: $size-tablet) {
            top: 17%; } } }

.related-product__carousel .card__image {
    height: 224px !important; }

@media screen and (max-width: 500px) {
    .related-product {
        margin: 60px 0 0; }

    .related-product .owl-nav {
 }        // margin-top: 40%
    .owl-prev img, .owl-next img {
        max-width: 15px; } }

