.productideal {
  padding-bottom: 5rem;
  margin-top: 0px;
  background: none;
  min-height: 761px;
  @media screen and (min-width: $size-web) {
    background: {
      image: url(/img/parts/jeitogaroto_bg.png);
      repeat: repeat; } } }


.productideal-article {
  width: 1427px;
  margin: 0 auto;
  padding: 100px 10px 0;
  background: none;
  @media screen and (min-width: $size-web) {
    padding: 39px 50px 0;
    height: 482px;
    background: {
      image: url(/img/parts/jeitogaroto_textbg.png);
      repeat: no-repeat;
      position: center; } }
  @extend %flexbox;
  @include flex-direction(column);
  @include align-items(center);

  @media (max-width: 90.625rem) {
    width: 100%; } }

.productideal-products {
  margin: 0px auto 40px;
  display: none;
  width: 100%;
  padding: 0 10px;
  @media screen and (min-width: 930px) {
    padding: 0;
    width: 930px; } }

.productideal-products-align {
  width: 100%;
  margin: 50px 0 0 0;
  display: inline-block; }

.productideal-products-cards {
  width: 100%;
  display: inline-block;
  margin: 0;
  padding-bottom: 40px; }

.productideal-title {
  font-size: 1.850rem;
  color: $dark;
  text-align: center;
  text-transform: uppercase;
  @media screen and (min-width: $size-web) {
    font-size: 1.850rem;
    text-transform: none;
    color: $light; } }
.productideal-title--black {
  font-size: 1.850rem;
  color: $dark;
  text-transform: uppercase;
  margin: 0 0 25px 0;
  padding: 0;
  text-align: center;
  @media screen and (min-width: $size-web) {
    display: inline-block;
    font-size: 1.850rem;
    text-align: left; } }


.productideal-subtitle {
  color: $dark;
  font-size: 1rem;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  text-align: center;
  @media screen and (min-width: $size-web) {
    text-align: left;
    text-transform: normal; } }

.productideal-quest {
  width: 100%;
  display: inline-block;
  text-align: center;
  display: none; }

.productideal-form {
  width: 100%;
  margin: 0;
  padding: 0;
  display: none;
  @media screen and (min-width: $size-web) {
    display: inline-block; } }

.productideal-select {
  width: 100%;
  font-size: 0.875rem;
  color: $colorGrey2;
  border: 1px solid $colorGrey2; }


.productideal-select-align {
  width: 100%;
  margin: 0 0 10px 0;
  display: none;
  position: relative;
  float: left; }

.productideal-form-mobile {
  width: 100%;
  margin: 0;
  padding: 0;
  @media screen and (min-width: $size-web) {
    display: none; }
  .select2-selection--single {
    font-size: 0.875rem;
    color: $colorGrey2;
    height: 35px;
    border: 1px solid $colorGrey3;
    text-transform: uppercase;
    border-radius: 0px;
    .select2-selection__arrow {
      top: 4px; } } }

.productideal-button {
  @extend %button;
  cursor: pointer;
  font-size: .8rem; }

.productideal-option {
  display: none; }

.productideal-submiting {
  width: 100%;
  text-align: center;
  color: $dark;
  font-size: 1rem;
  margin-bottom: 15px;
  display: none;
  @media screen and (min-width: $size-web) {
    margin-bottom: 0px;
    font-size: 1.5rem;
    color: $light; } }

.productideal-alert {
  display: none;
  text-align: center;
  width: 100%;
  color: $dark;
  font-size: 1.2rem;
  @media screen and (min-width: $size-web) {
    color: $light; } }

.select2-selection__rendered {
  color: $colorGrey2 !important;
  line-height: 34px !important; }


.select2-dropdown {
  top: 47px;
  border-radius: 0px;
  .select2-results__option {
    background: #f2f2f2;
    font-size: 0.875rem;
    color: $colorGrey2;
    text-transform: uppercase;
    border-bottom: 1px solid $colorGrey3; } }

.productideal-disabled {
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .select2-selection__arrow {
    display: none; } }
