.post {
  width: 90%;
  max-width: $container;
  margin: 5rem auto; }

.post-header {
  text-align: center; }

.post-header_title {
  font: {
    size: 3rem;
    weight: 600; }
  text-transform: uppercase; }

.post-content_subtitle {
  display: block; }
