.technology {
  width: 100%;
  min-height: 1760px;
  margin: -20px 0 30px;
  position: relative;
  background: url(/img/technology/bg_mobile.jpg) 43% 0 no-repeat;
  background-color: $dark;
  @media screen and (min-width: $size-web) {
    background: url(/img/technology/bg.jpg) center top no-repeat; }
  figure {
    margin: 0;
    padding: 0; }
  .products-footer {
    z-index: 0; }
  .no-mobile {
    display: none;
    @media screen and (min-width: $size-web) {
      display: inline-block; } } }

.technology-content {
  width: 100%;
  margin: 0 auto;
  @media screen and (min-width: $size-web) {
    width: 960px; } }

.technology-thermocontrol_web {
  display: none;
  @media screen and (min-width: $size-web) {
    display: inline-block; } }

.technology-thermocontrol_mobile {
  display: inline-block;
  img {
    width: 40%;
    margin: 100px 0 0 0;
    @media screen and (min-width: $size-tablet - 200px) {
      margin: 50px 0 0 0; } }
  @media screen and (min-width: $size-web) {
    display: none; } }

.technology-comfortrepair_mobile {
  display: inline-block;
  width: 100%;
  text-align: center;
  img {
    width: auto;
    margin: 0px 0 20px 0;

    @media screen and (max-width: $size-web - 1px) {
      max-width: 140px; }

    @media screen and (min-width: $size-tablet - 200px) {
      margin: 50px 0 0 0; } }
  @media screen and (min-width: $size-web) {
    // display: none
    float: left;
    width: 51%;
    margin: 70px 0 0 0 !important; } }

.technology-thermoControl {
  width: 100%;
  position: relative;
  text-align: center;
  display: inline-block;
  height: 840px;
  @media screen and (min-width: $size-web) {
    height: 1000px; } }

.technology-confortRepair {
  width: 100%;
  position: relative;
  display: inline-block; }

.technology-thermoControl-product {
  width: 60%;
  margin: 40px 0 0 0;
  @media screen and (min-width: $size-tablet - 350px) {
    width: 40%; }
  @media screen and (min-width: $size-tablet - 200px) {
    width: 30%; }
  @media screen and (min-width: $size-web) {
    margin: 0;
    width: auto;
    position: absolute;
    top: 161px;
    left: 6%; } }

.technology-confortRepair-product {
  position: relative;
  width: 60%;
  @media screen and (min-width: $size-web) {
    position: absolute;
    width: auto;
    top: -40px;
    right: 6%; } }

.technology-confortRepair-figure {
  float: left;
  position: relative;
  width: 100%;
  text-align: center;
  @media screen and (min-width: $size-web) {
    display: none; } }


.technology-right-content {
  width: 100%;
  margin: 5px 0 0 0;
  display: inline-block;
  position: relative;
  float: right;
  text-align: center;
  @media screen and (min-width: $size-web) {
    text-align: left;
    margin: 355px 0 0 0;
    width: 50%; }
  img {
    width: 60%;
    @media screen and (min-width: $size-tablet - 200px) {
      width: 30%; }
    @media screen and (min-width: $size-web) {
      width: auto; } } }


.technology-left-content {
  width: 100%;
  margin: 5px 0 0 0;
  display: inline-block;
  position: relative;
  float: none;
  text-align: left;
  @media screen and (min-width: $size-web) {
    width: 50%;
    // margin: 255px 0 0 0
    margin: 50px 0 0 0;
    float: left; } }

.technology-figure-right {
  width: 100%;
  text-align: center;
  @media screen and (min-width: $size-web) {
    text-align: right;
    width: 85%; }
  img {
    margin: 10px 0 0 0;
    width: 80%;
    @media screen and (min-width: $size-tablet) {
      width: auto; } } }

.technology-thermocontrol_logo {
  margin: 0 0 15px 35px; }

.technology-text {
  color: $light;
  font-size: 0.655rem;
  letter-spacing: 0.043rem;
  padding: 0;
  width: 80%;
  margin: 0 10%;
  text-align: center;
  @media screen and (min-width: $size-tablet - 200px) {
    width: 40%;
    margin: 0 30%; }
  @media screen and (min-width: $size-web) {
    text-align: left;
    margin: 0 0px 10px 0;
    width: 65%; }
  &.technology-text-big {
    font-size: 0.750rem;
    letter-spacing: 0.043rem;
    margin: 0 0px 10px 18%;
    width: 67%; } }
