.detail {
	width: 100%;
	padding: 0;

	@media screen and (min-width: $size-tablet) {
		margin: 65px 0 0; }

	@media screen and (min-width: $size-web) {
		width: 82.1052%; }
	&__line {
		font-size: 350px;
		position: absolute;
		top: 188px;
		right: -463px;
		display: none;
		@media screen and (min-width: $size-web) {
			display: block; }
		&--home {
			top: 40px;
			right: -22px; } }
	&__aside {
		.aside {
			display: none;
			@media screen and (min-width: $size-web) {
				display: inline-block; } } }
	&__grid {
		margin-left: 0px;
		font-weight: 500;
		letter-spacing: .9px;
		padding: 0 15px;
		max-width: 100% !important;

		@media screen and (min-width: $size-web) {
			margin-left: 23px;
			padding: 0; }

		@media screen and (min-width: $size-tablet) {
			max-width: 950px !important; } }


	&__thumb {
		width: 100%;
		float: left;
		text-align: center;
		border: 1px solid $colorGrey;
		padding: 15px;
		display: flex;
		align-items: center;

		@media screen and (min-width: $size-tablet) {
			width: 50%; } }

	&__product {
		width: 100%;
		margin: 0;
		padding: 0;
		text-align: center;
		display: inline-block;
		position: relative;
		float: left;

		img {
			width: auto;
			max-width: 100%;
			z-index: 99;
			position: relative; } }

	&__gradient {
		padding: 20px 10px;


		@media screen and (min-width: $size-tablet) {
			background: none;
			padding: 0;
			display: flex; } }

	&__description {
		width: 100%;
		float: right;
		text-align: center;
		z-index: 999;
		position: relative;
		display: inline-block;

		@media screen and (min-width: $size-tablet) {
			text-align: left;
			padding-left: 25px;
			width: 50%; }

		&__title {
			color: $dark;
			font-size: 2.063em;
			margin: 40px 0 0 10px;
			padding: 10px 15px 1px;
			font-weight: normal;
			display: inline-block;
			background: #ffc222;
			transform: skew(-14deg);
			-webkit-border-radius: 8px;
			-moz-border-radius: 8px;
			border-radius: 8px;
			text-transform: uppercase;
			@media screen and (min-width: $size-web) {
				font-size: 2.875rem; } }

		&__subtitle {
			color: $dark;
			margin: 0;
			margin: 8px 0 0 0;
			font-size: 1.250em;
			font-weight: normal;
			font-style: italic;
			@media screen and (min-width: $size-web) {
				font-size: 1.375rem; } }
		&__text {
			color: $dark;
			margin: 23px 0 0 0;
			padding: 0;
			font-size: 1rem; }

		&__buy {
			width: 100%;
			display: none;
			padding: 0;
			text-align: center;
			@media screen and (min-width: $size-web) {
				display: inline-block;
				margin: 40px 0 0 0; }
			&--mobile {
				display: inline-block;
				@media screen and (min-width: $size-web) {
					display: none; } }
			&__box {
				width: 100%;
				display: inline-block;
				text-align: center;
				@media screen and (min-width: $size-tablet) {
					width: 45%; } }
			&__image {}

			&__text {
				font-size: .9rem;
				color: $dark;
				display: inline-block;
				width: 100%;
				font-weight: normal;
				margin: 5px 0 0 0;
				text-transform: uppercase; } }
		&__technology {
			width: 100%;
			display: inline-block;
			margin: 20px 0 0 0;
			padding: 0;
			@media screen and (min-width: $size-tablet) {
				margin: 50px 0 0 0; }
			&__block {
				width: 100%;
				margin: 0;
				display: inline-block;
				vertical-align: middle;
				margin-bottom: 20px;
				padding: 0 10px;

				@media screen and (min-width: $size-web) {
					margin: 0 10px;
					padding: 0;

					&:first-of-type {
						width: 32%; }

					&:last-of-type {
						width: 52%; } }

				img {
					max-width: 200px; }
				&__text {
					text-transform: uppercase;
					font-size: 0.850rem;
					margin: 5px 0;
					color: $dark; }
				&__full-text {
					font-size: .9rem;
					color: $dark;
					margin: 0;
					padding: 0; }
				&__image {
					width: 100%; }
				&__link {
					color: $dark;
					font-size: 1rem;
					text-transform: uppercase;
					text-decoration: underline;
					margin: 0;
					font-weight: normal;
					display: inline-block;
					padding: 10px 0 0 0; } } } }
	&__ingredients {
		width: 100%;
		display: inline-block;
		margin: 30px 0 0 0;
		padding: 20px 10px;
		text-align: center;
		background: #c1c1c1;
		@media screen and (min-width: $size-tablet) {
			background: none;
			padding: 20px 0;
			margin: 60px 0 0 0; }

		@media screen and (min-width: $size-web) {
			padding: 0;
			text-align: left; }
		&__title {
			font-size: 1rem;
			color: $dark;
			font-weight: normal;
			font-style: italic;
			text-transform: uppercase;
			padding: 0;
			margin: 0 0 20px 0; }
		&__text {
			color: $dark;
			font-size: 1rem;
			padding: 0;
			margin: 0; } } }
