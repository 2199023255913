.header {
  position: relative;
  z-index: 9999;
  width: 100%;
  height: 5.2rem;
  background: {
    image: url(/img/parts/menu_bg.png);
    repeat: repeat-x;
    position: 2.3px top; }

  &:after {
    content: "";
    height: 2px;
    width: 100%;
    background: #f73740;
    display: block;
    position: absolute;
    bottom: 2px;
    right: 0;

    @media (max-width: 767px) {
      bottom: 2.5px; } }

  .container {
    width: $container;
    margin: auto;
    @extend %flexbox;
    @include align-items(center);
    transition: .2s all ease;
    position: relative;
    z-index: 9;

    @media (min-width: 120.0625rem) {
      width: 95%; }

    @media (max-width: 93.75rem) {
      width: 95%; }

    @media (max-width: 64rem) {
      width: 90%; } } }

.header-logo {
  width: 200px;
  height: 70px;
  transform: translateY(1.25rem);

  @media (max-width: 75rem) {
    width: 175px; }

  @media (max-width: 64rem) {
    z-index: 1;
    width: 150px; }

  &:after {
    content: '';
    position: absolute;
    top: 3.05rem;
    right: 0;
    left: -15rem;
    display: block;
    width: 30.875rem;
    height: 2.4375rem;
    background: {
      image: url(/img/parts/menu_bg-detail.png);
      repeat: no-repeat;
      position: 0 10px; }

    @media (max-width: 75rem) {
      left: -18rem; }

    @media (max-width: 64rem) {
      left: -15rem; }

    @media screen and (max-width: $size-tablet - 1px) {
      top: 3rem; } }

  svg, img {
    width: 200px;
    height: 66px;
    transition: .3s all ease;

    @media (max-width: 75rem) {
      width: 175px; }

    @media (max-width: 64rem) {
      width: 150px; }

    &:hover {
      transform: translateY(-.1875rem); } }

  img {
    height: auto;

    @media (max-width: 75rem) {
      margin-top: 10px; } } }
