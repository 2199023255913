// colors
$default-color: #00426d;
$second-color: #ee3a43;
$dark: #000;
$light: #fff;
$colorGrey: #e0dede;
$colorGrey2: #606060;
$colorGrey3: #afafaf;
$colorGrey4: #eeeeee;

$blue1: #1a8db6;
$blue2: #3375a0;

// typo
$font-family: 'Eurostile LT Std', sans-serif;
$font-size: 16px;
$font-weight: normal;

// spacer
$container: 89rem;
$min-container: 60rem;
$size-large: 1150px;
$size-tablet: 768px;
$size-web: 1024px;
