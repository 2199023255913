.products-nav {
  @extend %flexbox; }

.products-nav {
  position: relative;
  @include flex-direction(column);
  background: {
    image: url(/img/parts/menu-content_bg.png);
    repeat: no-repeat;
    position: top center; }

  @media (max-width: 64rem) {
    // height: 85px
    height: auto;
    background-position: top right; } }


.products-menu {
  width: 100%;
  height: 77px;
  @extend %flexbox;
  @include align-items(center);
  @include justify-content(center); }

.products-menu_item:not(:last-child) {
  margin-right: 1rem;

  @media (min-width: 550px) {
    margin-right: 1.6rem; } }

.products-menu_link {
  position: relative;
  display: block;
  padding: 3px 6px 6px 3px;
  font: {
    size: .7rem;
    weight: 500; }
  color: $light;
  text-transform: uppercase;
  transition: .25s all ease;

  @media screen and (min-width: $size-web) {
    font: {
      size: 1.2rem; } }

  &:hover,
  &:focus,
  &.active {
    text-shadow: 0 0 5px rgba($dark,.3), 0 0 10px $light, 0 0 15px $light, 0 0 20px $light;

    &:after {
      border-color: #fff; } }

  &:after {
    content: '';
    border-radius: 0 0 6px 0;
    border-right: 4px solid transparent;
    border-bottom: 2px solid transparent;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    transition: .25s all ease;
    transform: skewX(-15deg); } }

.products-submenu {
  height: 115px;
  margin-left: 25%;
  padding: 0 2rem;
  border-bottom: 2px solid $dark;
  @extend %flexbox;
  @include align-items(center);

  @media (max-width: 83.125rem) {
    margin-left: 20%; }

  @media (max-width: 76.25rem) {
    margin-left: 18%; }

  @media (max-width: 64rem) {
    display: inline-block;
    margin: 10px 0 0 0;
    height: auto;
    border: none;
    padding: 0;
 }    // display: none !important

  > div {
    @extend %flexbox;
    @include align-items(center);
    @include justify-content(center);
    @include flex-wrap(wrap);

    &#panel-0, &#panel-2 {
      @media screen and (max-width: 64rem) {
        height: 0;
        overflow: hidden;
        display: none !important; } } } }

.products-submenu_item {
  position: relative;
  width: 125px;
  text-align: center;
  font-size: 14px;

  @media screen and (max-width: 64rem) {
    margin: 5px 8px;
    border-bottom: 1px solid;
    font-size: 14px;
    width: auto; } }

.products-submenu_link {
  font: {
    style: italic; }
  color: $dark;
  text-transform: uppercase;
  transition: .3s text-shadow ease;

  &:hover,
  &:focus,
  &.active {
    font-weight: 900;
    text-shadow: 0 0 10px rgba(0,0,0,.5);

    &:after {
      border-bottom: 6px solid #000; } }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    bottom: -23px;
    display: block;
    max-width: 65px;
    margin: 0 auto;
    border: {
      left: 6px solid transparent;
      right: 6px solid transparent;
      bottom: 0 solid transparent; }
    transition: .2s all ease;

    @media screen and (max-width: 64rem) {
      display: none; } } }

.cabelo-menu_item .products-submenu_link:after {
  bottom: -13px; }
