.header-nav {
  @extend %flexbox; }

.header-nav {
  width: 100%;
  transform: translate(0, .325rem);
  @include align-items(center);
  @include justify-content(space-between);

  @media (max-width: 64rem) {
    @include justify-content(flex-end);
    transform: none; } }

.menu {
  margin-left: 5rem;
  @extend %flexbox;

  @media (max-width: 75rem) {
    margin-left: 2.5rem; }

  @media (max-width: 64rem) {
    overflow: hidden;
    height: 0;
    padding: 0;
    display: none; }

  &.active {
    transition: .2s height ease;
    @extend %flexbox;
    @media (max-width: 64rem) {
      position: absolute;
      top: 83px;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0;
      padding: 1.65rem 0 0;
      height: 100vh;
      background-color: rgba($light,.9);
      text-align: center;
      @include align-items(center);
      @include flex-direction(column); } } }

.menu-item {
  @media (max-width: 64rem) {
    width: 100%;
    padding: 1rem 0;
    border-top: 1px solid #cacaca; }

  &:not(:last-child) {
    margin-right: 2.4rem;

    @media (max-width: 64rem) {
      margin-right: 0; } }

  &:first-child {
    @media (max-width: 64rem) {
      border-top: 0; } }

  &:last-child {
    @media (max-width: 64rem) {
      border-bottom: 1px solid #cacaca; } } }

.menu-item_mobile {
  display: none;

  @media (max-width: 64rem) {
    display: block; } }

.menu-link {
  position: relative;
  display: block;
  padding: 3px 6px 6px 3px;
  font-weight: 500;
  color: $light;
  text-transform: uppercase;
  transition: .25s all ease;
  letter-spacing: 1.5px;

  @media (max-width: 64rem) {
    padding: 0;
    font-style: italic;
    color: $default-color; }

  &:hover {
    text-shadow: 0 0 5px rgba($dark,.3), 0 0 10px $light, 0 0 15px $light, 0 0 20px $light;

    @media (max-width: 64rem) {
      text-shadow: none; }

    &:after {
      border-color: #fff; } }

  &:after {
    content: '';
    border-radius: 0 0 6px 0;
    border-right: 4px solid transparent;
    border-bottom: 2px solid transparent;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    transition: .25s all ease;
    transform: skewX(-15deg);

    @media (max-width: 64rem) {
      content: none; } } }

.social-top {
  margin-right: 5px;
  @extend %flexbox;
  @include align-items(center);

  @media (max-width: 64rem) {
    transform: translateY(7px); } }

.social-top_item:not(:last-child) {
  margin-right: 1rem; }

.social-top_menu {
  display: none;
  cursor: pointer;
  width: 35px;
  height: 35px;
  background: {
    image: url(/img/icons/menu-head-icon.png);
    repeat: no-repeat;
    position: top center; }

  @media (max-width: 64rem) {
    display: block; } }

.social-top_search {
  position: relative;
  margin-left: .25rem;
  text-align: center;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: -25px;
    left: -5px;
    z-index: -1;
    display: block;
    width: 35px;
    height: 65px;
    background-color: #ef3e42;
    border-radius: 0 0 5px 5px; } }
