.dd-pagination {
    width: 100%;
    display: inline-block;
    margin-top: 20px;
    ul {
        list-style: none;
        width: 100%;
        display: inline-block;
        text-align: center; }

    li {
        color: $light;
        font-size: 14px;
        padding: 0;
        margin: 0;
        display: inline-block;

        &.disabled {
            display: none; }

        a, &.active {
            margin: 0 2px;
            padding: 8px 10px 4px;
            background: $blue1;
            background: {}
            display: inline-block;
            color: #FFF; }

        a:hover {
            background: $default-color; }

        &.active {
            color: $light;
            background: $default-color; } } }
