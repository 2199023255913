.jeitogaroto {
  padding-bottom: 5rem;
  background: {
    image: url(/img/parts/jeitogaroto_bg.png);
    repeat: repeat; } }

.jeitogaroto-article {
  width: 1427px;
  height: 482px;
  margin: 0 auto;
  padding: 100px 75px 0;
  background: {
    image: url(/img/parts/jeitogaroto_textbg.png);
    repeat: no-repeat;
    position: center; }
  @extend %flexbox;
  @include flex-direction(column);
  @include align-items(center);

  @media (max-width: 90.625rem) {
    width: 100%; } }


.jeitogaroto-description {
  width: 850px;
  margin: 0 auto;
  @extend %flexbox;
  @include align-items(center);
  @include justify-content(space-around);

  @media (max-width: 60rem) {
    width: 100%;
    @include flex-direction(column); } }

.jeitogaroto-description img {
  max-width: 100%;
  height: auto; }

.jeitogaroto_text {
  width: 360px;
  font-style: italic;
  color: $light;

  @media (max-width: 60rem) {
    text-align: center; } }

.jeitogaroto_button {
  margin-top: 5rem;
  @extend %button; }

@media (max-width: 380px) {
  .jeitogaroto_text {
    width: 320px; } }
