* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none; }



body {
  // margin-bottom: -47px
  font: {
    family: $font-family;
    size: $font-size;
    weight: $font-weight; }
  // transform: translateY(-47px)
  // margin-top: -52px
  transition: .2s all ease;
  background-color: $colorGrey4;

  &.active-search {
    .search {
      margin-top: 0px;
      transition: .2s all ease; }
 } }    // transform: translateY(0px)

.container {
  width: 960px;
  margin: 0 auto;

  @media (max-width: 64rem) {
    width: 90%; } }

.main {
  position: relative; }

// body { height: 100%; overflow: auto; }

.select2-dropdown {
  top: 0 !important; }
