.card {
    width: 44.6%;
    display: inline-block;
    margin: 5px;
    padding: 0 10px;
    border: 1px solid $colorGrey;
    border-bottom: 4px solid $second-color !important;
    vertical-align: top;
    padding: 10px 10px 20px 10px;

    @media screen and (min-width: $size-tablet) {
        width: 47.6%; }
    @media screen and (min-width: $size-web) {
        width: 22.6%; }

    &__content {
        display: block;
        width: 100%;
        min-height: 55px;

        @media screen and (max-width: 470px) {
            min-height: 70px; } }

    &__image {
        width: 100%;
        overflow: hidden;
        display: flex;
        text-align: center;
        margin: 0;
        padding: 0;
        height: 210px;
        @media screen and (min-width: $size-tablet) {
            height: 224px; }
        img {
            max-width: 250px;
            width: 100% !important;
            object-fit: contain;
            height: 100%;
            max-height: 210px;

            @media screen and (min-width: $size-tablet) {
                max-height: 224px; } } }

    &__title {
        color: $dark;
        font-size: 0.688rem;
        font-weight: bold;
        text-transform: uppercase;
        margin: 0;
        padding: 0 5px 0 0;
        font-style: oblique;
        @media screen and (min-width: $size-tablet) {
            text-align: center; } }

    &__description {
        color: $dark;
        font-size: 0.625rem;
        text-transform: uppercase;
        font-style: oblique;
        margin: 0;
        padding: 5px 5px 0 0px;
        @media screen and (min-width: $size-tablet) {
            text-align: center; } }

    &__buy {
        width: 100%;
        display: inline-block;
        text-align: left;
        margin: 12px 0 0 0;
        padding: 0;
        @media screen and (min-width: $size-tablet) {
            text-align: center; }

        &__image {
            display: inline-block;
            width: auto;
            position: relative;
            top: 4px;
            width: 1.4rem; }

        &__text {
            display: inline-block;
            color: $dark;
            font-size: 0.703em;
            font-weight: bold;
            text-transform: uppercase;

            @media screen and (max-width: 470px) {
                width: 70px;
                vertical-align: middle; } } } }
