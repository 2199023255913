.filter {
	width: 100%;
	display: block;
	padding: 0 2%;
	margin: 0;
	text-align: center;

	@media (min-width: $size-tablet) {
		display: none; } }

.filter__button {
	width: 49.2%;
	color: $colorGrey2;
	text-transform: uppercase;
	font-size: 0.875rem;
	font-weight: bold;
	font-style: italic;
	border: 1px solid $colorGrey2;
	display: inline-block;
	text-align: center;
	margin: 0;
	padding: 4px 0 10px; }

.filter__icon {
	position: relative;
	top: 5px;
	margin: 0 5px 0 0; }
