.banners {
  .owl-dots {
    position: absolute;
    right: 0;
    bottom: 5%;
    left: 0; } }

.owl-theme .owl-dots span {
  width: 7px;
  height: 7px;
  background: $light !important;
  box-shadow: 1px 2px 5px rgba($dark, .2); }

.owl-theme .owl-dots .owl-dot.active span {
  background: $second-color !important; }

.banners-home {

  .owl-nav {
    pointer-events: none;

    .owl-next {
      pointer-events: auto; }

    .owl-prev {
      pointer-events: auto; } }

  .banners-item {
    background-position: center;
    // background-size: 650px
    // height: 195px
    background-size: 550px;
    height: 168px;

    @media (min-width: 400px) {
      background-size: 790px;
      height: 242px; }

    @media (min-width: 550px) {
      background-size: 850px;
      height: 260px; }

    @media (min-width: 700px) {
      background-size: 1200px;
      height: 365px; }


    @media (min-width: 992px) {
      background-image: none !important;
      height: auto; }

    img {
      display: none;

      @media (min-width: 992px) {
        display: block; } } } }


.related-product__carousel {
  .owl-item {
    padding: 0 10px;

    @media screen and (max-width: 470px) {
      padding: 0 2px; } } }


.carousel-barba[data-panel="panel-1"]:not(.owl-hidden) {
  > .owl-dots {
    display: none; } }
