%button {
  position: relative;
  display: inline-block;
  margin: 1rem;
  padding: .75rem 1.5rem;
  font: {
    size: .9rem;
    weight: 600; }
  color: $light;
  text-transform: uppercase;
  transition: .3s all ease;
  transform: translate(0, -.8rem);

  &:hover {
    text-shadow: 0 0 .3125rem rgba($light, .2), 0 0 .625rem rgba($light, .4), 0 0 .9375rem rgba($light, .6); }
  &.selected {
    text-shadow: 0 0 .3125rem rgba($light, .2), 0 0 .625rem rgba($light, .4), 0 0 .9375rem rgba($light, .6); }

  &:hover:before {
    box-shadow: 0 0 .3125rem rgba($light, .2), 0 0 .625rem rgba($light, .4); }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border: 3px solid $light;
    transition: .3s all ease;
    transform: skew(-12deg);
    border-radius: .3125rem; } }
