.aside {
  width: 98%;
  margin: 10px 1%;
  @extend %flexbox;
  display: none;
  border: 1px solid $colorGrey;

  @media (min-width: $size-tablet) {
    margin: 0;
    width: 171px;
    @include flex-direction(column);
    @include align-items(left);
    display: inline-block; } }

.aside__menu {
  width: 100%;
  // padding: 5px 15px
  margin: 0;
  list-style: none; }

.aside__menu__category {
  a {
    @include fade;
    width: 100%;
    display: inline-block;
    &:hover {
      color: $light;
      background: $default-color;
      @include fade; } } }

.aside__menu__category__list {
  width: 100%;
  padding: 15px 0 10px 0px;
  margin: 0;
  text-transform: uppercase;
  color: $dark;
  position: relative;
  font: {
    size: 0.813rem;
    weight: normal;
    style: italic; }

  &:not(:last-child):after {
    content: '';
    display: inline-block;
    height: 1px;
    margin: 0 auto;
    width: calc(100% - 30px);
    border-bottom: 1px dotted $second-color;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px; }

  &:last-child {
    border: none; }

  a {
    // padding: 2px 5px
    padding: 5px 10px 2px 10px;
    color: $blue2; } }

.aside__menu__category__list__subcategory {
  width: 100%;
  margin: 10px 0 0 0; }

.aside__menu__category__list__subcategory__list {
  margin: 3px 0;
  text-transform: none;
  font: {
      size: 0.85rem;
      weight: normal;
      style: normal; }

  a {
    color: $dark;
    padding: 5px 10px 2px 20px; }

  &.has-children {
    ul {
      margin-top: 0;

      a {
        font-weight: 500;
        padding-left: 30px; } } } }

.aside__menu__category__list__subcategory__list__product {
  width: 100%;
  padding: 0;
  margin: 3px 0; }

.aside__menu__category__list__subcategory__list__product__list {
  font-size: 0.750rem;
  font-weight: normal;

  a {
    // padding: 2px 5px 2px 19px
    padding: 5px 10px 2px 10px; } }
