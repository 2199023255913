.breadcrumb {
	display: none;
	@media screen and (min-width: $size-tablet) {
		width: 100%;
		display: inline-block;
		margin: 10px 0;
		padding: 0 15px; }

	&__link {
		color: $dark;
		font-size: 0.750rem;
		font-weight: bold;
		text-transform: uppercase;
		display: inline-block;
		font-style: italic;
		@include fade;
		&:hover {
			@include fade;
			color: $second-color; }
		&::after {
			content: ">";
			margin: 0 0 0 8px;
			position: relative;
			top: -1px;
			color: $dark; }
		&:last-child {
			&::after {
				content: ""; } } } }
