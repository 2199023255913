.footer {
  position: relative;
  // max-width: 1920px
  width: 100%;
  margin: 0 auto;
  color: $light;
  z-index: 999999;
  background: {
    image: url(/img/parts/footer_bg.png);
    repeat: no-repeat;
    color: #FFF;
    position: center; }

  &:before {
    content: '';
    position: absolute;
    top: -30px;
    width: 100%;
    height: 35px;
    display: block;
    background: {
      image: url(/img/parts/footer_bg-detail.png);
      repeat: no-repeat;
      position: center; } } }

.footer-contact {
  padding: 2rem 0 3rem;
  @extend %flexbox;
  @include align-items(center);
  @include justify-content(center);

  @media (max-width: 40.625rem) {
    @include flex-direction(column); } }

.sac {
  margin-right: 2rem;
  font-size: 2rem;
  text-align: right;

  @media (max-width: 40.625rem) {
    margin: {
      right: 0;
      bottom: 1.6rem; }
    text-align: center; }

  a {
    color: inherit;
    text-decoration: none; } }

.sac-title {
  margin: 0;
  font: {
    style: italic;
    weight: 500;
    size: 1em; }
  text-transform: uppercase;
  letter-spacing: 2px; }

.sac-tel {
  margin: 0;
  font-weight: 900;
  color: inherit;
  text-decoration: none; }

.social-list {
  @extend %inline-flex;
  @include align-items(center); }

.social-item:not(:first-child) {
  margin-left: 2rem; }

.footer-categories {
  @extend %flexbox;
  @include justify-content(space-between);

  @media (max-width: 63.9375rem) {
    @include align-items(center);
    @include justify-content(center); }

  &:first-child {
    font: {
      size: .9rem;
      weight: 600; }
    text-transform: uppercase; } }

.footer-nav {
  @extend %flexbox;
  @include flex-direction(column);

  @media (max-width: 63.9375rem) {
    @include align-items(center); }


  &:first-child {
    font: {
      size: .9rem;
      weight: 600; }
    text-transform: uppercase; } }

.footer-nav_products {
  @media (max-width: 63.9375rem) {
    display: none; } }

.footer-nav_title {
  margin: 0;
  font: {
    style: italic;
    weight: 600; }
  text: {
    indent: -1rem;
    transform: uppercase; }
  line-height: 2rem; }

.footer-nav_item {
  color: $light;
  font-style: italic;
  line-height: 2rem;
  transition: .3s all ease;

  &:hover {
    transform: translateX(.5rem);

    @media (max-width: 63.9375rem) {
      transform: none; } } }

.footer-copyright {
  padding: 1.5rem 0;
  text-align: center;

  svg, img {
    width: 100px;
    height: 25px;
    transition: .3s all ease;

    &:hover {
      transform: translateY(-.25rem); } }

  img {
    height: auto; } }
