.search {
  height: 50px;
  margin-top: -48px;
  background-color: #ef3e42;
  @extend %flexbox;
  @include align-items(center);
  @include justify-content(center);
  transition: .2s all ease;
  z-index: 999;
  position: relative;

  form {
    width: 100%; }

  .container {
    width: 89rem;
    @extend %flexbox;
    @include align-items(center);
    @include justify-content(center);

    @media (max-width: 93.75rem) {
      width: 95%; }

    @media (max-width: 64rem) {
      width: 90%; } } }

.search_input {
  width: 100%;
  margin-right: .5rem;
  padding: .5rem 1rem;
  border: 0;
  font: {
    family: $font-family;
    weight: 300;
    style: italic; }
  border-radius: 3px; }

.search_button {
  @extend %button;
  margin: 0;
  padding: .585rem 1.5rem;
  border: 0;
  font: {
    family: $font-family;
    weight: 600; }
  background-color: transparent;
  transform: translate(0,0);

  &:before {
    transform: none;
    border-width: 2px;
    border-radius: 3px; } }
