.internal {
  min-height: 300px;
  width: 100%;
  margin: 60px 0 48px;
  padding-bottom: 0;
  position: relative;
  @media screen and (min-width: $size-tablet) {
      margin: 60px 0 56px; }
  @media screen and (min-width: $size-large) {
      padding-bottom: 120px; }

  .products-footer {
    bottom: -3.5rem;
    z-index: 0;
    -webkit-clip-path: none;
    clip-path: none; } }

.internal-content {
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  display: inline-block;
  @extend %flexbox;
  @media screen and (min-width: $size-tablet) {
      display: flex;
      @include align-items(left); } }
