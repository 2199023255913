.products {
  position: relative;
  background-image: -webkit-linear-gradient(top, #fff 0%, #b8b8b8 100%);
  background: $colorGrey4; }

.products-content {
  padding-top: 5rem;

  @media (max-width: 64rem) {
    margin-top: 3rem;
    padding-top: 0; } }

.products-content .item {
  width: $min-container;
  height: 45.625rem;
  margin: 0 auto;
  @extend %flexbox;
  @include justify-content(space-between);

  @media (max-width: 64rem) {
    width: 100%;
    height: auto;
    @include align-items(center);
    @include justify-content(center);
    @include flex-direction(column); } }

[data-hash="acao-prolongada"] .products-img_top {
  margin-top: 16rem;

  @media (max-width: 64rem) {
    margin-top: 0; } }

[data-hash="aspecto-molhado"] .products-img,
[data-hash="creme-modelador"] .products-img,
[data-hash="multivitaminas"] .products-img,
[data-hash="protecao-solar"] .products-img,
[data-hash="cera-modeladora"] .products-img,
[data-hash="cera-modeladora"] .products-img {
  margin-top: 5rem;

  @media (max-width: 64rem) {
    margin-top: 0; }

  .products-img_top {
    margin-top: 11rem;

    @media (max-width: 64rem) {
      margin-top: 0; } } }

.products-img {
  position: relative;
  float: left;

  @media (max-width: 64rem) {
    float: none;
    width: 100%; }

  &:after {
    content: '';
    position: absolute;
    top: 12rem;
    left: 25.4rem;
    display: block;
    width: 555px;
    height: 500px;
    background: {
      image: url(/img/banners/products/prod-line.png);
      repeat: no-repeat; }

    @media (max-width: 64rem) {
      content: none; } } }

.products-img_top {
  z-index: 2;
  position: absolute;
  width: auto !important;
  max-width: 100%;
  margin: 0 auto 20px auto;

  @media screen and (min-width: $size-web) {
    // transform: translateX(-85px)
    // max-width: none !important
    transform: translateX(-35px);
    max-width: 640px !important;
    margin-bottom: 0; }

  @media (max-width: 64rem) {
    position: initial; } }

.products-description {
  position: relative;
  z-index: 1;
  float: right;
  width: 430px;
  margin-top: 7.5rem;

  @media (max-width: 64rem) {
    float: none;
    margin-top: 0;
    text-align: center; }

  @media (max-width: 39.375rem) {
    width: 90%; } }

.products-description_title {
  margin: 0;
  font: {
    size: 2.6rem;
    style: italic;
    weight: 500; }
  text-transform: uppercase; }

.products-description_subtitle {
  margin: 0;
  font: {
    style: italic;
    weight: 500; } }

.products-info_tech,
.products-info_buttons {
  @extend %flexbox;
  @include align-items(center); }

.products-info_tech {
  @media (max-width: 64rem) {
    margin-top: 3rem;
    text-align: center;
    @include flex-direction(column); } }

.products-info_buttons {
  margin: 1.5rem 0;
  @include justify-content(space-around); }

.products-buy,
.products-details {
  font-weight: 600;
  color: $dark;
  z-index: 100;
  position: relative;
  text-transform: uppercase; }

.products-buy_img,
.products-details_img {
  display: block;
  width: auto !important;
  margin: 0 auto .5rem; }

.products-tech_img {
  width: auto !important;
  margin-right: 1.5rem;

  @media (max-width: 64rem) {
    margin-right: 0; } }

.products-tech_more {
  display: block;
  margin-top: .5rem;
  font-weight: 600;
  color: $dark;
  text: {
    decoration: underline;
    transform: uppercase; } }

.products-footer {
  position: absolute;
  bottom: -3rem;
  z-index: 1;
  width: 100%;
  height: 10rem;
  background: {
    image: url(/img/parts/products-footer_bg.png);
    repeat: no-repeat;
    size: cover; }
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 70%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 70%);
  @extend %flexbox;
  @include align-items(center);
  @include justify-content(center);

  @media (max-width: 64rem) {
    position: relative; } }

.products-ideal-footer {
  position: absolute;
  bottom: -1rem;
  z-index: 1;
  width: 100%;
  height: 10rem;
  background: {
    image: url(/img/parts/products-footer_bg.png);
    repeat: no-repeat;
    size: cover; }
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 70%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 70%);
  @extend %flexbox;
  @include align-items(center);
  @include justify-content(center);

  @media (max-width: 64rem) {
    position: relative; } }


.products-footer-timeline {
  margin-top: -49px;
  @media screen and (min-width: $size-web) {
    margin-top: 0;
    z-index: 9999999;
    bottom: -8rem; } }

.products-button {
  @extend %button; }

.products-tech_text {
  position: relative;
  z-index: 100; }

.carousel-bar .owl-stage-outer .owl-stage > .owl-item {
  overflow: hidden; }

.carousel-sub {
  position: relative;

  .products-description {
    @media screen and (min-width: $size-web) {
      padding-right: 70px; } }

  .item {
    overflow: hidden; }

  .owl-nav {
    position: absolute;
    top: 40%;
    right: -75px;
    left: -75px;
    @extend %flexbox;
    @include justify-content(space-between);

    [class*=owl-],
    [class*=owl-]:hover,
    [class*=owl-]:active, {
      background: none; } } }


.carousel-barba {
  .owl-nav {
    position: absolute;
    right: 15px;
    left: 15px;
    top: 30%;
    margin: auto;
    max-width: 1100px;
    @extend %flexbox;
    @include justify-content(space-between);

    [class*=owl-],
    [class*=owl-]:hover,
    [class*=owl-]:active, {
      background: none; } }

  &:nth-child(2) > .owl-nav {
    display: none; } }

.banners-home {
  .owl-nav {
    position: absolute;
    left: -5px;
    right: -5px;
    top: 40%;
    margin: auto;
    max-width: 800px;
    @extend %flexbox;
    @include justify-content(space-between);

    @media screen and (min-width: 470px) {
      left: 15px;
      right: 15px; }

    @media screen and (min-width: 1200px) {
      max-width: 1100px; }

    @media screen and (min-width: 1920px) {
      max-width: 1400px; }

    [class*=owl-],
    [class*=owl-]:hover,
    [class*=owl-]:active, {
      background: none; } } }
